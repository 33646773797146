/* eslint-disable react/prop-types */

/* Plugins */
import * as React from 'react';
import slugify from 'slugify';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

/* Components */
import {
  Container, Heading, PostCard, SEO,
} from '../../components/components';

/* Messages */
import * as messages from '../../messages/blog.messages';

/* Styles */
const PostsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 25px;

  ${breakpoint('md')`
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 30px;
  `}

  ${breakpoint('lg')`
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 40px;
  `}
`;

const BlogPage = ({ data }) => {
  const {
    allDatoCmsArticle: { nodes },
  } = data;
  return (
    <>
      <SEO title={messages.title} />
      <Container subPage>
        <Heading headingLevel="h1" title={messages.mainHeader} centered />
        <PostsWrapper>
          {nodes.map(({
            title, teaser, featureImage, meta,
          }) => (
            meta.status === 'published' && (
            <PostCard
              key={title}
              title={title}
              teaser={teaser}
              image={featureImage.fluid}
              slug={slugify(title, { lower: true })}
            />
            )
          ))}
        </PostsWrapper>
      </Container>
    </>
  );
};

export const query = graphql`
  {
    allDatoCmsArticle {
      nodes {
        title
        teaser
        featureImage {
          fluid(maxWidth: 330) {
            ...GatsbyDatoCmsFluid
          }
        }
        articleContent {
          ... on DatoCmsParagraph {
            paragraphContent
          }
        }
        meta {
          status
        }
      }
    }
  }
`;

export default BlogPage;
